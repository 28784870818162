import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  offers: ItemProps[]
}

export const OffersList = memo(function OffersList({ offers }: Props) {
  if (offers.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper row wrap>
        {uniqBy(offers, 'title').map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 60px auto 214px;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-bottom: 8.5rem;
    padding: 0 1.3125rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-right: -3.75rem;

  > a {
    width: calc(50% - 3.75rem);
    margin: 3.75rem 3.75rem 0 0;
  }

  @media (max-width: 1023px) {
    margin-right: -2.5rem;

    > a {
      width: calc(50% - 2.5rem);
      margin-right: 2.5rem;
    }
  }

  @media (max-width: 767px) {
    margin-right: 0;

    > a {
      width: 100%;
      margin-right: 0;
    }
  }
`
